/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
div.navi {
  float: left;
  display: block;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
div.sub1 > .item > .menu {
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
  margin-left: 4px;
}
div.sub1 > .item > .menu:before {
  content: '| ';
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus {
  color: #90814e;
}
div.sub2 {
  position: relative;
  z-index: 3;
}
div.sub2 > .item {
  margin: 26px 24px 0;
  padding-bottom: 21px;
  position: relative;
}
div.sub2 > .item.init {
  margin-left: 0;
}
div.sub2 > .item.exit {
  margin-right: 0;
}
div.sub2 > .item:hover > .menu:after,
div.sub2 > .item:focus > .menu:after {
  width: 100%;
  left: 0;
}
div.sub2 > .item > .menu {
  font-size: 16px;
  line-height: 1.25;
  color: #000;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  padding-bottom: 6px;
}
div.sub2 > .item > .menu:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 1px;
  background: #000;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.sub2 > .item > .menu.path:after {
  width: 100%;
  left: 0;
}
@media (max-width: 1199px) {
  div.sub2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  div.sub2 > .item {
    margin: 0;
  }
}
div.sub3 {
  position: absolute;
  left: -20px;
  top: 100%;
  background: rgba(0, 0, 0, 0.7);
  min-width: 100%;
  padding: 20px 20px 8px;
  display: none;
}
div.sub3:after {
  content: '';
  position: absolute;
  top: 100%;
  box-sizing: border-box;
  left: 0;
  width: 100%;
  border-top: 12px solid rgba(0, 0, 0, 0.7);
  border-right: 12px solid transparent;
}
div.sub3 > .item {
  width: 100%;
  margin: 8px 0;
}
div.sub3 > .item.init {
  margin-top: 0;
}
div.sub3 > .item.exit {
  margin-bottom: 0;
}
div.sub3 > .item > .menu {
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
}
div.sub3 > .item > .menu:before {
  content: '- ';
}
div.sub3 > .item > .menu:hover,
div.sub3 > .item > .menu:focus,
div.sub3 > .item > .menu.path {
  color: #90814e;
}
div.sub2 > .item:hover div.sub3 {
  display: block;
}
.desk {
  max-width: 88%;
}
#home {
  height: 154px;
  margin-top: 40px;
  margin-bottom: 0;
}
#head {
  width: 56.61577608%;
  margin-left: 21.69211196%;
  margin-right: 21.69211196%;
}
.service_adults {
  bottom: unset;
  top: 117px;
  right: 0;
  font-size: 34px;
  line-height: 1.17647059;
  padding: 5px 20px;
  transition: transform 0.6s 1s cubic-bezier(0, 1.29, 0.68, 1.2);
  transform: translateX(100%);
}
body.show-content .service_adults {
  transform: translateX(0);
  right: 0;
}
.quicklinks {
  top: 290px;
  width: 50px;
}
.quicklinks .meta {
  width: 50px;
  height: 50px;
  background-size: 28px 28px;
}
.navigation,
.multimood,
.booking-mask {
  width: 89.05852417%;
  margin-left: 5.47073791%;
  margin-right: 5.47073791%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side .foot {
  margin-right: 1.04895105%;
  margin-left: 1.04895105%;
}
.side .part {
  margin-right: 1.04895105%;
  margin-left: 1.04895105%;
  width: 97.9020979%;
}
.side .tiny {
  width: 47.9020979%;
}
.side > .slim {
  width: 50%;
}
.side > .slim .foot,
.side > .slim .part {
  margin-right: 2.0979021%;
  margin-left: 2.0979021%;
}
.side > .slim .part {
  width: 95.8041958%;
}
.side > .slim .tiny {
  width: 95.8041958%;
}
div.side {
  width: 100%;
  padding-left: 4.45292621%;
  padding-right: 4.45292621%;
}
.maincontent .area div.pure {
  width: 56.61577608%;
}
.maincontent .area div.seam,
.maincontent .area div.flat {
  margin-bottom: 130px;
}
.maincontent .area div.seam div.pict,
.maincontent .area div.flat div.pict {
  width: 43.69426752%;
  height: calc(100% + 67px);
}
.maincontent .area div.seam div.pict img,
.maincontent .area div.flat div.pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.maincontent .area div.seam {
  padding-right: 5.34351145%;
  padding-left: 50.89058524%;
}
.maincontent .area div.seam div.pict {
  left: 5.35031847%;
}
.maincontent .area div.flat {
  padding-right: 50.89058524%;
  padding-left: 5.34351145%;
}
.maincontent .area div.flat div.pict {
  right: 5.35031847%;
}
.maincontent .area div.edge {
  padding-right: 21.62849873%;
  padding-left: 21.62849873%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */